import axios from 'axios';
// import router from '../routerManuaConfig'
import router from '../router/index'
import store from "../store";
import Vue from 'vue';


let base = '';
// 如果是IIS部署，用这个，因为 IIS 只能是 CORS 跨域，不能代理
//let base = process.env.NODE_ENV=="production"? 'http://api.training.wanghuolou.cn:82':'';
//let base = process.env.NODE_ENV=="production"? 'http://192.168.1.4:5001':'';

//let base = process.env.NODE_ENV=="production"? 'http://192.168.1.4:1004':'';
var newBase = "https://edu.yongningxiaofang.com"

// 请求延时
axios.defaults.timeout = 20000

var storeTemp = store;
axios.interceptors.request.use(
    config => {
        var curTime = new Date()
        var expiretime = new Date(Date.parse(storeTemp.state.tokenExpire))
        if (storeTemp.state.token && (curTime < expiretime && storeTemp.state.tokenExpire)) {
            // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers.Authorization = "Bearer " + storeTemp.state.token;
        }

        saveRefreshtime();

        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

// http response 拦截器
axios.interceptors.response.use(
    Response => {
        return Response;
    },
    error => {
        // 超时请求处理
        var originalRequest = error.config;
        console.log(error.config)
        if(error.code == 'ECONNABORTED' && error.message.indexOf('timeout')!=-1 && !originalRequest._retry){

            Vue.prototype.$message({
                message: '请求超时！',
                type: 'error'
            });

            originalRequest._retry = true
            return null;
        }
        if (error.response) {
            if (error.response.status == 401) {
                var curTime = new Date()
                var refreshtime = new Date(Date.parse(window.localStorage.refreshtime))
                // 在用户操作的活跃期内
                if (window.localStorage.refreshtime && (curTime <= refreshtime)) {
                    return  refreshToken({token: window.localStorage.Token}).then((res) => {
                        if (res.Success) {
                            // Vue.prototype.$message({
                            //     message: 'refreshToken success! loading data...',
                            //     type: 'success'
                            // });

                            store.commit("saveToken", res.Response.token);

                            var curTime = new Date();
                            var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + res.Response.expiresIn));
                            store.commit("saveTokenExpire", expiredate);

                            error.config.__isRetryRequest = true;
                            error.config.headers.Authorization = 'Bearer ' + res.Response.token;
                            return axios(error.config);
                        } else {
                            // 刷新token失败 清除token信息并跳转到登录页面
                            ToLogin()
                            return false;   //代码显示此处共走了n(n取决于页面create接口的请求次数)遍，所以让停止再执行看看效果 date:2020-12-23
                        }
                    });
                } else {
                    // 返回 401，并且不知用户操作活跃期内 清除token信息并跳转到登录页面
                    ToLogin()
                    return false;   //代码显示此处共走了n(n取决于页面create接口的请求次数)遍，所以让停止再执行看看效果 date:2020-12-23
                }

            }
            // 403 无权限
            if (error.response.status == 403) {
                Vue.prototype.$message({
                    message: '失败！该操作无权限',
                    type: 'error'
                });
                return null;
            }
            // 429 ip限流
            if (error.response.status == 429) {
                Vue.prototype.$message({
                    message: '刷新次数过多，请稍事休息重试！',
                    type: 'error'
                });
                return null;
            }
            // 500
            if (error.response.status == 500) {
                setTimeout(function(){
                    Vue.prototype.$message({
                        message: '服务器错误，请稍后重试！',
                        type: 'error'
                    });
                },1000)
                return null;
            }
        }
        return ""; // 返回接口返回的错误信息
    }
);

const ToLogin = params => {
     
    store.commit("saveToken", "");
    store.commit("saveTokenExpire", "");
    store.commit("saveTagsData", "");
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('NavigationBar');

    var routerGo = router.currentRoute.fullPath.substring(0,6)
    if(routerGo == "/login"){
    }else{
        router.replace({
            path: "/login",
            query: {redirect: router.currentRoute.fullPath}
        });
    }
    // router.replace({
    //     path: "/login",
    //     query: {redirect: router.currentRoute.fullPath}
    // });

    // 调试登录过期后重新登录第一次无法跳转到相应的页面，而是停留在登录页面，所以暂时注释，怀疑重新加载所以看不到console.log("redirect_api--") date:2020-12-23
    window.location.reload()
    return false;  //代码显示此处共走了n(n取决于页面create接口的请求次数)遍，所以让停止再执行看看效果 date:2020-12-23
};


export const BaseApiUrl =base;

export const saveRefreshtime = params => {

    let nowtime = new Date();
    let lastRefreshtime = window.localStorage.refreshtime ? new Date(window.localStorage.refreshtime) : new Date(-1);
    let expiretime = new Date(Date.parse(window.localStorage.TokenExpire))

    let refreshCount=1;//滑动系数
    if (lastRefreshtime >= nowtime) {
        lastRefreshtime=nowtime>expiretime ? nowtime:expiretime;
        lastRefreshtime.setMinutes(lastRefreshtime.getMinutes() + refreshCount);
        window.localStorage.refreshtime = lastRefreshtime;
    }else {
        window.localStorage.refreshtime = new Date(-1);
    }
};



// 登录获取token
export const requestLogin = params => {
    return axios.get(newBase + `/api/login/jwttoken`, {params: params}).then(res => res.data);
};

//刷新token
export const refreshToken = params => {
    return axios.get(newBase + `/api/login/RefreshToken`, {params: params}).then(res => res.data);
};

//系统管理
//用户管理
export const getUserListPage = params => {
    return axios.get(newBase + `/api/SystemManager/GetUserInfoListPage`, {params: params});
};

// 根据token获取用户详细信息
export const getUserByToken = params => {
    return axios.get(newBase + `/api/SystemManager/GetUserInfoByToken`, {params: params}).then(res => res.data);
};

//新增用户信息
export const addUserInfo = params => {
    return axios.post(newBase + `/api/SystemManager/AddUserInfo`,  params);
};

//更新用户信息
export const updateUserInfo = params => {
    return axios.post(newBase + `/api/SystemManager/UpdateUserInfo`,  params);
};

//删除用户信息
export const deleteUserInfo = params => {
    return axios.post(newBase + `/api/SystemManager/DeleteUserInfo`,  params);
};

//发送修改密码手机短信验证码
export const sendModifyPasswordSMSVerificationCode = params => {
    return axios.post(newBase + `/api/SystemManager/SendModifyPasswordSMSVerificationCode`,  params);
};

//修改用户登录密码
export const modifyUserPassword = params => {
    return axios.post(newBase + `/api/SystemManager/ModifyUserPassword`,  params);
};

//系统管理
//菜单权限管理

//根据用户ID获取路由树
export const getNavigationBar = params => {
    return axios.get(newBase + `/api/SystemManager/GetNavigationBar`, {params: params}).then(res => res.data);
};

//获取菜单树
export const getMenuTree = params => {
    return axios.get(newBase + `/api/SystemManager/GetMenuTree`, {params: params});
};

//获取菜单权限树形列表
export const getMenuPermissionsTree = params => {
    return axios.get(newBase + `/api/SystemManager/GetMenuPermissionsTree`, {params: params});
};

//新增菜单权限信息
export const addMenuPermissionsInfo = params => {
    return axios.post(newBase + `/api/SystemManager/AddMenuPermissionsInfo`,  params);
};

//更新菜单权限信息
export const updateMenuPermissionsInfo = params => {
    return axios.post(newBase + `/api/SystemManager/UpdateMenuPermissionsInfo`,  params);
};

//删除菜单权限信息
export const deleteMenuPermissionsInfo = params => {
    return axios.post(newBase + `/api/SystemManager/DeleteMenuPermissionsInfo`,  params);
};

//保存菜单权限分配
export const assignMenuPermissions = params => {
    return axios.post(newBase + `/api/SystemManager/AssignMenuPermissions`,  params);
};

//通过角色获取菜单【无权限】
export const GetAssignMenuPermissionIdByRoleId = params => {
    return axios.get(newBase + `/api/SystemManager/GetAssignMenuPermissionIdByRoleId`, {params: params});
};


//系统管理
//角色管理

//根据组织结构ID获取角色信息集合
export const getRoleInfosByOrganizationInfoID = params => {
    return axios.get(newBase + `/api/SystemManager/GetRoleInfosByOrganizationInfoID`, {params: params});
};

//获取角色信息分页列表
export const getRoleListPage = params => {
    return axios.get(newBase + `/api/SystemManager/GetRoleInfoListPage`, {params: params});
};

//新增角色信息
export const addRoleInfo = params => {
    return axios.post(newBase + `/api/SystemManager/AddRoleInfo`,  params);
};

//更新角色信息
export const updateRoleInfo = params => {
    return axios.post(newBase + `/api/SystemManager/UpdateRoleInfo`,  params);
};

//删除角色信息
export const deleteRoleInfo = params => {
    return axios.post(newBase + `/api/SystemManager/DeleteRoleInfo`,  params);
};

//系统管理
// 组织结构管理

//获取组织结构树
export const getOrganizationTree = params => {
    return axios.get(newBase + `/api/SystemManager/GetOrganizationTree`, {params: params});
};

//新增组织结构信息
export const addOrganizationInfo = params => {
    return axios.post(newBase + `/api/SystemManager/AddOrganizationInfo`,  params);
};

//更新组织结构信息
export const updateOrganizationInfo = params => {
    return axios.post(newBase + `/api/SystemManager/UpdateOrganizationInfo`,  params);
};

//删除组织结构信息
export const deleteOrganizationInfo = params => {
    return axios.post(newBase + `/api/SystemManager/DeleteOrganizationInfo`,  params);
};

//系统管理
// 接口管理

//获取接口信息树形列表
export const getApiModulesInfoTree = params => {
    return axios.get(newBase + `/api/SystemManager/GetApiModulesInfoTree`, {params: params});
};

//获取接口菜单树
export const getApiMenuTree = params => {
    return axios.get(newBase + `/api/SystemManager/GetApiMenuTree`, {params: params});
};

//新增接口信息
export const addApiModulesInfo = params => {
    return axios.post(newBase + `/api/SystemManager/AddApiModulesInfo`,  params);
};

//更新接口信息
export const updateApiModulesInfo = params => {
    return axios.post(newBase + `/api/SystemManager/UpdateApiModulesInfo`,  params);
};

//删除接口信息
export const deleteApiModulesInfo = params => {
    return axios.post(newBase + `/api/SystemManager/DeleteApiModulesInfo`,  params);
};

//系统管理
// 数据权限管理

//获取全部数据权限信息列表
export const getAllDataPermissionsInfoList = params => {
    return axios.get(newBase + `/api/SystemManager/GetAllDataPermissionsInfoList`, {params: params});
};

//新增数据权限信息
export const addDataPermissionsInfo = params => {
    return axios.post(newBase + `/api/SystemManager/AddDataPermissionsInfo`,  params);
};

//更新数据权限信息
export const updateDataPermissionsInfo = params => {
    return axios.post(newBase + `/api/SystemManager/UpdateDataPermissionsInfo`,  params);
};

//删除数据权限信息
export const deleteDataPermissionsInfo = params => {
    return axios.post(newBase + `/api/SystemManager/DeleteDataPermissionsInfo`,  params);
};

//保存数据权限分配
export const assignDataPermissions = params => {
    return axios.post(newBase + `/api/SystemManager/AssignDataPermissions`,  params);
};

//通过角色ID获取数据权限菜单【无权限】
export const getAssignDataPermissionIdByRoleId = params => {
    return axios.get(newBase + `/api/SystemManager/GetAssignDataPermissionIdByRoleId`, {params: params});
};


//系统管理
// Task管理

export const getTaskListPage = params => {
    return axios.get(newBase + `/api/TasksQz/get`, {params: params});
};
export const removeTask = params => {
    return axios.delete(newBase + `/api/TasksQz/delete`, {params: params});
};
export const editTask = params => {
    return axios.put(newBase + `/api/TasksQz/put`, params);
};
export const addTask = params => {
    return axios.post(newBase + `/api/TasksQz/post`, params);
};

export const startJob = params => {
    return axios.get(newBase + `/api/TasksQz/StartJob`, {params: params});
};
export const stopJob = params => {
    return axios.get(newBase + `/api/TasksQz/StopJob`, {params: params});
};
export const reCovery = params => {
    return axios.get(newBase + `/api/TasksQz/ReCovery`, {params: params});
};


//通用设置
//审批流程管理

//获取审批流程信息分页列表
export const getApprovalProcessListPage = params => {
    return axios.get(newBase + `/api/CommonSettings/GetApprovalProcessListPage`, {params: params});
};

//新增审批流程
export const addApprovalProcess = params => {
    return axios.post(newBase + `/api/CommonSettings/AddApprovalProcess`,  params);
};

//更新审批流程
export const updateApprovalProcess = params => {
    return axios.post(newBase + `/api/CommonSettings/UpdateApprovalProcess`,  params);
};

//根据审批流程ID 获取审批流程图
export const getApprovalProcessDiagramByApprovalProcessID = params => {
    return axios.get(newBase + `/api/CommonSettings/GetApprovalProcessDiagramByApprovalProcessID`, {params: params});
};



//通用设置
//行政区域


//获取行政区信息列表
export const getAreaDictionaryList = params => {
    return axios.get(newBase + `/api/CommonSettings/GetAreaDictionaryList`, {params: params});
};

//新增行政区信息
export const addAreaDictionary = params => {
    return axios.post(newBase + `/api/CommonSettings/AddAreaDictionary`,  params);
};

//更新行政区信息
export const updateAreaDictionary = params => {
    return axios.post(newBase + `/api/CommonSettings/UpdateAreaDictionary`,  params);
};

//删除行政区信息
export const deleteAreaDictionary = params => {
    return axios.post(newBase + `/api/CommonSettings/DeleteAreaDictionary`,  params);
};

//抓取行政区信息
export const grabAreaDictionary = params => {
    return axios.post(newBase + `/api/CommonSettings/GrabAreaDictionary`,  params);
};


//通用设置
//数据字典信息

//获取数据字典信息列表
export const getDataDictionaryList = params => {
    return axios.get(newBase + `/api/CommonSettings/GetDataDictionaryList`, {params: params});
};

//新增数据字典信息
export const addDataDictionary = params => {
    return axios.post(newBase + `/api/CommonSettings/AddDataDictionary`,  params);
};

//更新数据字典信息
export const updateDataDictionary = params => {
    return axios.post(newBase + `/api/CommonSettings/UpdateDataDictionary`,  params);
};

//删除数据字典信息
export const deleteDataDictionary = params => {
    return axios.post(newBase + `/api/CommonSettings/DeleteDataDictionary`,  params);
};






//工作流管理

//审批
export const approve = params => {
    return axios.post(newBase + `/api/WorkFlow/Approve`,  params);
};

//获取待处理信息分页列表
export const getPendingIssuesListPage = params => {
    return axios.get(newBase + `/api/WorkFlow/GetPendingIssuesListPage`, {params: params});
};

//获取已处理信息分页列表
export const getProcessedIssuesListPage = params => {
    return axios.get(newBase + `/api/WorkFlow/GetProcessedIssuesListPage`, {params: params});
};

//获取已发起信息分页列表
export const getLaunchIssuesListPage = params => {
    return axios.get(newBase + `/api/WorkFlow/GetLaunchIssuesListPage`, {params: params});
};

//获取全部事项分页列表
export const getAllIssuesListPage = params => {
    return axios.get(newBase + `/api/WorkFlow/GetAllIssuesListPage`, {params: params});
};



//主数据管理
//教材管理
//获取教材信息
export const getTeachingMaterialTree = params => {
    return axios.get(newBase + `/api/MasterData/GetTeachingMaterialTree`, {params: params});
};

//新增教材信息
export const addTeachingMaterialInfo = params => {
    return axios.post(newBase + `/api/MasterData/AddTeachingMaterialInfo`,  params);
};

//更新教材信息
export const updateTeachingMaterialInfo = params => {
    return axios.post(newBase + `/api/MasterData/UpdateTeachingMaterialInfo`,  params);
};

//删除教材信息
export const deleteTeachingMaterialInfo = params => {
    return axios.post(newBase + `/api/MasterData/DeleteTeachingMaterialInfo`,  params);
};

//教材关联试题
export const relationTeachingExamination = params => {
    return axios.post(newBase + `/api/MasterData/RelationTeachingExamination`,  params);
};

//试题管理
//获取试题信息分页列表
export const getExaminationQuestionsListPage = params => {
    return axios.get(newBase + `/api/MasterData/GetExaminationQuestionsListPage`, {params: params});
};

//根据教材ID获取试题分页集合
export const getExaminationQuestionsByTMIDListPageAsync = params => {
    return axios.get(newBase + `/api/MasterData/GetExaminationQuestionsByTMIDListPageAsync`, {params: params});
};

//新增试题
export const addExaminationQuestions = params => {
    return axios.post(newBase + `/api/MasterData/AddExaminationQuestions`,  params);
};

//编辑试题
export const updateExaminationQuestions = params => {
    return axios.post(newBase + `/api/MasterData/UpdateExaminationQuestions`,  params);
};

//删除试题信息
export const deleteExaminationQuestions = params => {
    return axios.post(newBase + `/api/MasterData/DeleteExaminationQuestions`,  params);
};
//获取两级考试卷纸教材信息树
export const getExamPaperTeachingMaterialTree = params => {
    return axios.get(newBase + `/api/MasterData/GetExamPaperTeachingMaterialTree`, {params: params});
};

//教材视频管理
//获取视频分类分页集合
export const getTeachingVideoCategoryListPage = params => {
    return axios.get(newBase + `/api/MasterData/GetTeachingVideoCategoryListPage`, {params: params});
};

//获取视频分类列表
export const getTeachingVideoCategory = params => {
    return axios.get(newBase + `/api/MasterData/GetTeachingVideoCategory`, {params: params});
};

//根据Id获取视频分类
export const GetTeachingVideoCategoryInfo = params => {
    return axios.get(newBase + `/api/MasterData/GetTeachingVideoCategoryInfo`, {params: params});
};

//新增视频分类
export const addTeachingVideoCategory = params => {
    return axios.post(newBase + `/api/MasterData/AddTeachingVideoCategory`, params);
};

//编辑视频分类
export const updateTeachingVideoCategoryInfo = params => {
    return axios.post(newBase + `/api/MasterData/UpdateTeachingVideoCategoryInfo`,  params);
};

//删除视频分类
export const deleteTeachingVideoCategory = params => {
    return axios.post(newBase + `/api/MasterData/DeleteTeachingVideoCategory`,  params);
};

//获取视频目录分页集合
export const getTeachingVideoCatalogListPage = params => {
    return axios.get(newBase + `/api/MasterData/GetTeachingVideoCatalogListPage`, {params: params});
};

//获取视频目录列表
export const getTeachingVideoCatalog = params => {
    return axios.get(newBase + `/api/MasterData/GetTeachingVideoCatalog`, {params: params});
};

//根据Id获取视频目录
export const getTeachingVideoCatalogInfo = params => {
    return axios.get(newBase + `/api/MasterData/GetTeachingVideoCatalogInfo`, {params: params});
};

//新增视频目录
export const addTeachingVideoCatalog = params => {
    return axios.post(newBase + `/api/MasterData/AddTeachingVideoCatalog`, params);
};

//编辑视频目录
export const updateTeachingVideoCatalogInfo = params => {
    return axios.post(newBase + `/api/MasterData/UpdateTeachingVideoCatalogInfo`,  params);
};

//删除视频目录
export const deleteTeachingVideoCatalog = params => {
    return axios.post(newBase + `/api/MasterData/DeleteTeachingVideoCatalog`,  params);
};

//获取教材视频分页集合
export const getTeachingVideoListPage = params => {
    return axios.get(newBase + `/api/MasterData/GetTeachingVideoListPage`, {params: params});
};

//新增教材视频
export const addTeachingVideoInfo = params => {
    return axios.post(newBase + `/api/MasterData/AddTeachingVideoInfo`,  params);
};

//编辑教材视频
export const updateTeachingVideoInfo = params => {
    return axios.post(newBase + `/api/MasterData/UpdateTeachingVideoInfo`,  params);
};

//删除教材视频
export const deleteTeachingVideo = params => {
    return axios.post(newBase + `/api/MasterData/DeleteTeachingVideo`,  params);
};

//上传图片
export const uploadTempPic = params => {
    return axios.post(newBase + `/api/Image/UploadTempPic`,  params);
};


// 上传图片路径
export const uploadTempPicUrl = newBase + `/api/Image/UploadTempPic`


//公告管理
//获取公告信息分页集合
export const getAnnouncementListPage = params => {
    return axios.get(newBase + `/api/CommonSettings/GetAnnouncementListPage`, {params: params});
};

//根据ID获取公告信息详情
export const getAnnouncementByID= params => {
    return axios.get(newBase + `/api/CommonSettings/GetAnnouncementByID`, {params: params});
};

//新增公告信息
export const addAnnouncement = params => {
    return axios.post(newBase + `/api/CommonSettings/AddAnnouncement`,  params);
};

//编辑公告信息
export const updateAnnouncement = params => {
    return axios.post(newBase + `/api/CommonSettings/UpdateAnnouncement`,  params);
};

//删除公告信息
export const deleteAnnouncement = params => {
    return axios.post(newBase + `/api/CommonSettings/DeleteAnnouncement`,  params);
};

//顶置/取消顶置公告信息
export const setTopAnnouncement = params => {
    return axios.post(newBase + `/api/CommonSettings/SetTopAnnouncement`,  params);
};

//展示信息
//获取展示信息分页集合
export const getShowInformationListPage = params => {
    return axios.get(newBase + `/api/CommonSettings/GetShowInformationListPage`, {params: params});
};

//根据ID获取展示信息详情
export const getShowInformationByID= params => {
    return axios.get(newBase + `/api/CommonSettings/GetShowInformationByID`, {params: params});
};

//新增展示信息
export const addShowInformation = params => {
    return axios.post(newBase + `/api/CommonSettings/AddShowInformation`,  params);
};

//编辑展示信息
export const updateShowInformation = params => {
    return axios.post(newBase + `/api/CommonSettings/UpdateShowInformation`,  params);
};

//删除展示信息
export const deleteShowInformation = params => {
    return axios.post(newBase + `/api/CommonSettings/DeleteShowInformation`,  params);
};


//学习中（暂不需要，废弃）
//获取学生学习信息
export const getStudentLearningListPage = params => {
    return axios.get(newBase + `/api/LearningSystem/GetStudentLearningListPage`, {params: params});
};

//机构学生学习人数汇总
export const getAgencyLearningListPage = params => {
    return axios.get(newBase + `/api/LearningSystem/GetAgencyLearningListPage`, {params: params});
};

//学习记录
//学习打卡记录
export const getStudentLearningRecordPage = params => {
    return axios.get(newBase + `/api/LearningSystem/GetStudentLearningRecordPage`, {params: params});
};
//学习打卡明细
export const getStudentLearningAttendancePage = params => {
    return axios.get(newBase + `/api/LearningSystem/GetStudentLearningAttendancePage`, {params: params});
};
//学习报名汇总
export const getLearningEnrollSummaryListPage = params => {
    return axios.get(newBase + `/api/LearningSystem/GetLearningEnrollSummaryListPage`, {params: params});
};
//学习记录汇总
export const getLearningRecordSummaryListPage = params => {
    return axios.get(newBase + `/api/LearningSystem/GetLearningRecordSummaryListPage`, {params: params});
};

//学员学生管理
//账号管理
export const getStudentLearningAccountListPage = params => {
    return axios.get(newBase + `/api/LearningSystem/GetStudentLearningAccountListPage`, {params: params});
};
//启用、禁用学员考生账号
export const enableOrDisableStudentLearningAccount = params => {
    return axios.post(newBase + `/api/LearningSystem/EnableOrDisableStudentLearningAccount`,  params);
};
//档案管理
export const getStudentLearningIdentityListPage = params => {
    return axios.get(newBase + `/api/LearningSystem/GetStudentLearningIdentityListPage`, {params: params});
};
//学生报名信息
export const getLearnEnrollListPage = params => {
    return axios.get(newBase + `/api/LearningSystem/GetLearnEnrollListPage`, {params: params});
};

// 禁用报名信息
export const disableStudentLearningEnroll = params => {
    return axios.get(newBase + `/api/LearningSystem/DisableStudentLearningEnroll`, {params: params});
};

//同步学习时长
export const manuallySynchronizeLearningRecords = params => {
    return axios.get(newBase + `/api/LearningSystem/ManuallySynchronizeLearningRecords`,  {params: params});
};

//寄出教材
export const setBookLearningEnrollPostInfo = params => {
    return axios.get(newBase + `/api/LearningSystem/SetBookLearningEnrollPostInfo`,  {params: params});
};

//学员学生管理
//学习档案

// 获取学习档案管理列表
export const getStudentLearningCertificatePage = params => {
    return axios.get(newBase + `/api/LearningSystem/GetStudentLearningCertificatePage`, {params: params});
};

// 获取学习档案模拟考试记录
export const getCertificateExamineePaperResponseRecordPage = params => {
    return axios.get(newBase + `/api/LearningSystem/GetCertificateExamineePaperResponseRecordPage`, {params: params});
};

// 获取学习档案自由练习记录
export const getCertificateExamIntelligentRecordPage = params => {
    return axios.get(newBase + `/api/LearningSystem/GetCertificateExamIntelligentRecordPage`, {params: params});
};

// 获取学习视频记录
export const getCertificateStudentLearningRecordPage = params => {
    return axios.get(newBase + `/api/LearningSystem/GetCertificateStudentLearningRecordPage`, {params: params});
};


//机构管理
//查询机构详情
export const getAgencyInfo = params => {
    return axios.get(newBase + `/api/AgencyManager/GetAgencyInfo`, {params: params});
};

//新增培训机构
export const addAgencyInfo = params => {
    return axios.post(newBase + `/api/AgencyManager/AddAgencyInfo`,  params);
};

//编辑培训机构
export const updateAgencyInfo = params => {
    return axios.post(newBase + `/api/AgencyManager/UpdateAgencyInfo`,  params);
};

//培训机构列表
export const getAgencyInfoListPage= params => {
    return axios.get(newBase + `/api/AgencyManager/GetAgencyInfoListPage`, {params: params});
};

//培训机构审核
export const approveAgencyInfo = params => {
    return axios.post(newBase + `/api/AgencyManager/ApproveAgencyInfo`,  params);
};

//删除培训机构
export const deleteAgencyInfo = params => {
    return axios.post(newBase + `/api/AgencyManager/DeleteAgencyInfo`,  params);
};

//保利威视频上传
export const getPolyvUserData= params => {
    return axios.get(newBase + `/api/LearningSystem/GetPolyvUserData`, {params: params});
};

//保利威视频播放
export const getPlaysafeToken= params => {
    return axios.get(newBase + `/api/LearningSystem/GetPlaysafeToken`, {params: params});
};

//考试管理
//获取考试项目分页列表
export const getExamProjectInfoListPage = params => {
    return axios.get(newBase + `/api/LearningSystem/GetExamProjectInfoListPage`, {params: params});
};

//新增考试项目
export const addExamProjectInfo = params => {
    return axios.post(newBase + `/api/LearningSystem/AddExamProjectInfo`,  params);
};

//编辑考试项目
export const updateExamProjectInfo = params => {
    return axios.post(newBase + `/api/LearningSystem/UpdateExamProjectInfo`,  params);
};

//删除考试项目
export const deleteExamProjectInfo = params => {
    return axios.post(newBase + `/api/LearningSystem/DeleteExamProjectInfo`,  params);
};

//发布考试项目
export const publishExamProjectInfo = params => {
    return axios.post(newBase + `/api/WorkFlow/PublishExamProjectInfo`,  params);
};

//根据审批记录ID获取发布考试项目详情
export const getPublishExamProjectInfoByID = params => {
    return axios.get(newBase + `/api/WorkFlow/GetPublishExamProjectInfoByID`, {params: params});
};

//试卷管理
//获取考试试卷分页列表
export const getExaminationPaperListPage = params => {
    return axios.get(newBase + `/api/LearningSystem/GetExaminationPaperListPage`, {params: params});
};

//根据试卷ID获取考试试卷信息
export const getExaminationPaperByID = params => {
    return axios.get(newBase + `/api/LearningSystem/GetExaminationPaperByID`, {params: params});
};

//新增考试试卷
export const addExaminationPaper = params => {
    return axios.post(newBase + `/api/LearningSystem/AddExaminationPaper`,  params);
};

//编辑考试试卷
export const updateExaminationPaper = params => {
    return axios.post(newBase + `/api/LearningSystem/UpdateExaminationPaper`,  params);
};

//删除考试试卷
export const deleteExaminationPaper = params => {
    return axios.post(newBase + `/api/LearningSystem/DeleteExaminationPaper`,  params);
};

//启用/禁用考试试卷
export const enableOrDisableExaminationPaper = params => {
    return axios.post(newBase + `/api/LearningSystem/EnableOrDisableExaminationPaper`,  params);
};

//使用/未使用考试试卷
export const changeKeepOnFileExaminationPaper = params => {
    return axios.post(newBase + `/api/LearningSystem/ChangeKeepOnFileExaminationPaper`,  params);
};

//取消发布考试项目
export const CancelExamProjectInfo = params => {
    return axios.post(newBase + `/api/LearningSystem/CancelExamProjectInfo`,  params);
};

//获取教材列表
export const getTeachingMaterialList = params => {
    return axios.get(newBase + `/api/MasterData/GetTeachingMaterialList`, {params: params});
};

//发票管理
//获取学员发票列表
export const getStudentInvoiceAllPageList = params => {
    return axios.get(newBase + `/api/LearningSystem/GetStudentInvoiceAllPageList`, {params: params});
};